module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-theme-ghost-toc/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/@aaromp/gatsby-plugin-plausible/gatsby-browser.js'),
      options: {"plugins":[],"customDomain":"data.aaronward.info","domain":"aaronward.info"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
